import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AES, enc, pad } from "crypto-js";
import { ApiUsersService } from "src/app/services/api-users.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  userValidated: boolean;
  validationMsg: string;
  encrypt: Record<string, any> = {};

  forgotForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private APIUsers: ApiUsersService,
  ) {
    this.forgotForm = this.fb.group({
      id_user: [null, Validators.required],
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/),
        ],
      ],
    });

    this.route.params.subscribe((params) => {
      if (!params["token"]) {
        this.router.navigate(["login"]);
      } else {
        this.APIUsers.validateUserByToken(params["token"]).subscribe({
          next: (response: any) => {
            this.userValidated = true;
            this.forgotForm.get("id_user")?.setValue(response.data.id);
            this.validationMsg =
              "Ingrese una nueva contraseña para su usuario.";
          },
          error: () => {
            this.userValidated = false;
            this.validationMsg = "El usuario que se intenta validar no existe.";
          },
        });
      }
    });
  }

  ngOnInit(): void {
    this.encrypt["key"] = enc.Hex.parse("ecb4bf74b9d399ba25f737c7529f2437");
    this.encrypt["iv"] = enc.Hex.parse("eb2daf7ba35c59225faafc3752227d94");
  }

  changePassword() {
    const userId = this.forgotForm.get("id_user")!.value;
    let password = this.forgotForm.get("password")!.value;

    password = AES.encrypt(password, this.encrypt["key"], {
      iv: this.encrypt["iv"],
      padding: pad.ZeroPadding,
    }).toString();

    if (this.forgotForm.valid) {
      this.APIUsers.changePassword(userId, password).subscribe({
        next: (response: any) => {
          this.forgotForm.get("password")?.setValue("");
          alert(
            "Su contraseña se modificó correctamente. Utilice su nueva contraseña para iniciar sesión.",
          );
          this.backToLogin();
        },
        error: () => {
          this.forgotForm.get("email")?.setValue("");
          alert(
            "Ocurrió un error al modificar su contraseña. Intente nuevamente.",
          );
        },
      });
    }
  }

  backToLogin() {
    this.router.navigate(["login"]);
  }
}
