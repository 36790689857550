<div
  class="containers"
  style="
    display: flex;
    min-height: 100vh;
    flex-direction: row;
    box-sizing: border-box;
    place-content: center space-around;
    align-items: center;
  "
>
  <div class="max-size-flex">
    <h1>Registro de Usuario</h1>
    <mat-card>
      <registration-form></registration-form>
    </mat-card>
  </div>
</div>
