import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { EnrollmentStatusEnum } from "src/app/enums/enrollment-status-enum";
import { Course } from "src/app/models/course";
import { EnrollRequest } from "src/app/models/enroll-requests";
import { EnrollmentDialog } from "src/app/models/enrollment-dialog";
import { EnrollmentStatus } from "src/app/models/enrollment-status";
import { ApiCoursesService } from "src/app/services/api-courses.service";
import { ApiEnrollmentStatusService } from "src/app/services/api-enrollment-status.service";
import { ApiStudentsService } from "src/app/services/api-students.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

@Component({
  selector: "app-enrollment-dialog",
  templateUrl: "./enrollment-dialog.component.html",
  styleUrls: ["./enrollment-dialog.component.scss"],
})
export class EnrollmentDialogComponent implements OnInit {
  enrollFinished: boolean = false;

  enrollmentForm: UntypedFormGroup;

  courses: Course[] = [];
  enrollmentStatus: EnrollmentStatus[] = [];

  enrollmentStatusValues: typeof EnrollmentStatusEnum = EnrollmentStatusEnum;

  courseIsFull: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnrollmentDialog,
    private fb: UntypedFormBuilder,
    private APICourses: ApiCoursesService,
    private APIEnrollmentStatus: ApiEnrollmentStatusService,
    private APIStudents: ApiStudentsService,
    private ui: UserInterfaceService,
  ) {
    this.enrollmentForm = this.fb.group({
      id: [null, Validators.required],
      id_course: [null, Validators.required],
      id_person: [null, Validators.required],
      id_enrollment_status: [null, Validators.required],
    });

    this.ui.spin$.next(true);

    this.enrollmentForm.statusChanges.subscribe();
  }

  ngOnInit(): void {
    this.enrollmentForm.get("id")?.setValue(this.data.studentId);
    this.enrollmentForm.get("id_person")?.setValue(this.data.personId);

    this.getEnrollmentStatus().then(() => {
      this.getCourses().then(() => {
        if (this.data.courseId) {
          this.enrollmentForm.get("id_course")?.setValue(this.data.courseId);
          this.enrollmentForm.get("id_course")?.disable();
          this.onCourseChange();
        } else {
          this.enrollmentForm.get("id_course")?.enable();
        }

        this.ui.spin$.next(false);
      });
    });
  }

  getCourses(): Promise<void> {
    return new Promise((resolve) => {
      this.APICourses.getAll().subscribe((response) => {
        this.courses = response.data;
        resolve();
      });
    });
  }

  getEnrollmentStatus(): Promise<void> {
    return new Promise((resolve) => {
      this.APIEnrollmentStatus.getAll().subscribe((response) => {
        this.enrollmentStatus = response.data;
        resolve();
      });
    });
  }

  onCourseChange() {
    const cSelected = this.courses.find(
      (c) => c.id.toString() == this.enrollmentForm.get("id_course")!.value,
    );
    if (cSelected?.vacancies != 0) {
      if (cSelected?.vacancies == cSelected?.enrollments) {
        this.courseIsFull = true;
        this.enrollmentForm
          .get("id_enrollment_status")
          ?.setValue(this.enrollmentStatusValues.REJECTED.toString());
      } else {
        this.courseIsFull = false;
      }
    }
  }

  enroll() {
    if (this.enrollmentForm.valid) {
      let enrollRequest = new EnrollRequest();
      enrollRequest = this.enrollmentForm.getRawValue();

      this.APIStudents.enrollStudent(enrollRequest).subscribe((response) => {
        this.enrollFinished = true;
      });
    }
  }
}
