import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ChartConfiguration, ChartOptions } from "chart.js";
import { ChartModule } from "primeng/chart";

@Component({
  selector: "custom-chart-component",
  templateUrl: "./custom-chart.component.html",
  styleUrls: ["./custom-chart.component.scss"],
  imports: [ChartModule, CommonModule],
  standalone: true,
})
export class CustomChartComponent implements OnInit {
  @Input() chartData: number[] | undefined;
  @Input() chartType: string | undefined;
  @Input() chartLabels: string[] | undefined;
  @Input() chartLegend: boolean | undefined;
  @Input() chartTitle: string | undefined;
  chartOptions: ChartOptions | undefined;
  dataToRender: any;

  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary",
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

    this.dataToRender = {
      labels: this.chartLabels,
      datasets: [
        {
          label: this.chartTitle,
          data: this.chartData,
          fill: true,
          borderColor: "#4bc0c0",
        },
      ],
    };

    this.chartOptions = {
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary,
            borderColor: surfaceBorder,
          },
        },
      },
    } as ChartConfiguration["options"];
  }
}
