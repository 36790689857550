<div class="container-courselist">
  <div style="flex: 1 1 1e-9px; box-sizing: border-box">
    <div
      *ngIf="
        !allPersonListTableStructure.data?.length &&
        !licenseListTableStructure.data?.length
      "
    >
      <mat-spinner></mat-spinner>
    </div>
    <mat-card
      *ngIf="
        !(
          !allPersonListTableStructure.data?.length &&
          !licenseListTableStructure.data?.length
        )
      "
    >
      <mat-card-content>
        <custom-table
          *ngIf="
            allPersonListTableStructure.data?.length && licenseType === 'all'
          "
          [dataSource]="allPersonListTableStructure"
          [filterable]="true"
          [showActions]="true"
          [showEditButton]="false"
          (editButtonPressed)="openEditLicenseDialog($event)"
          [showDeleteButton]="false"
          (deleteButtonPressed)="delete($event)"
          [showDetailsButton]="false"
          [showGenericButton2]="true"
          [genericButton2]="createLicenseButton"
          [genericButton2Title]="createLicenseButton.title || 'Crear'"
          (genericButton2Pressed)="openCreateLicenseDialog($event.id_person)"
        ></custom-table>
        <p
          *ngIf="
            !(
              !allPersonListTableStructure.data?.length &&
              !licenseListTableStructure.data?.length
            ) &&
            !allPersonListTableStructure.data?.length &&
            licenseType === 'all'
          "
        >
          No hay Legajos que mostrar
        </p>
        <custom-table
          *ngIf="
            licenseListTableStructure.data?.length && licenseType !== 'all'
          "
          [dataSource]="licenseListTableStructure"
          [filterable]="true"
          [showActions]="true"
          [showEditButton]="true"
          (editButtonPressed)="openEditLicenseDialog($event)"
          [showDeleteButton]="false"
          (deleteButtonPressed)="delete($event)"
          [showDetailsButton]="false"
          [showGenericButton2]="false"
        >
        </custom-table>
        <p
          *ngIf="
            !licenseListTableStructure.data?.length && licenseType !== 'all'
          "
        >
          No hay Licencias {{ licenseType }} que mostrar
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
