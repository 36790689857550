import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { HttpBaseResponse } from "../models/base-response";
import { Course } from "../models/course";
import { ApiBaseService } from "./api-base.service";

@Injectable({
  providedIn: "root",
})
export class ApiCoursesService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "courses";
  }

  /**
   * @ngdoc function
   * @name getAll
   * @param courseId number
   * @methodOf ApiCoursesService
   * @description Se obtiene la información de los cursos (o el curso según ID enviado).
   * @return HttpBaseResponse
   */

  getAll(courseId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (courseId) endpoint += `/${courseId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name getList
   * @param courseId number
   * @methodOf ApiCoursesService
   * @description Se obtiene la información de los cursos para el LISTADO.
   * @return HttpBaseResponse
   */

  getList(courseId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/list`;
    if (courseId) endpoint += `/${courseId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name getStudentsReport
   * @methodOf ApiCoursesService
   * @description Se obtiene la información del reporte por curso.
   * @return HttpBaseResponse
   */

  getStudentsReport() {
    const endpoint = `${this._apiUrl}/${this._controller}/students_report`;
    return this.http.get<HttpBaseResponse>(endpoint);
  }

  /**
   * @ngdoc function
   * @name set
   * @param courseId number
   * @methodOf ApiPaymentsService
   * @description Crea/edita un curso.
   * @return HttpBaseResponse
   */

  set(course: Course) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/form`,
      course,
    );
  }

  /**
   * @ngdoc function
   * @name delete
   * @param courseId number
   * @methodOf ApiPaymentsService
   * @description Elimina el curso.
   * @return HttpBaseResponse
   */

  delete(courseId: number) {
    const data = { id: courseId };
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/delete`,
      data,
    );
  }
}
