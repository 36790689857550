import { Component } from "@angular/core";
import { PrimeNGConfig } from "primeng/api";
import { definePreset } from "primeng/themes";
import { Aura } from "primeng/themes/aura";

const theme = {
  primary: {
    0: "rgb(var(--primary-0))",
    50: "rgb(var(--primary-50))",
    100: "rgb(var(--primary-100))",
    200: "rgb(var(--primary-200))",
    300: "rgb(var(--primary-300))",
    400: "rgb(var(--primary-400))",
    500: "rgb(var(--primary-500))",
    600: "rgb(var(--primary-600))",
    700: "rgb(var(--primary-700))",
    800: "rgb(var(--primary-800))",
    900: "rgb(var(--primary-900))",
    950: "rgb(var(--primary-950))",
  },
  surface: {
    0: "rgb(var(--surface-0))",
    50: "rgb(var(--surface-50))",
    100: "rgb(var(--surface-100))",
    200: "rgb(var(--surface-200))",
    300: "rgb(var(--surface-300))",
    400: "rgb(var(--surface-400))",
    500: "rgb(var(--surface-500))",
    600: "rgb(var(--surface-600))",
    700: "rgb(var(--surface-700))",
    800: "rgb(var(--surface-800))",
    900: "rgb(var(--surface-900))",
    950: "rgb(var(--surface-950))",
  },
};

const CustomPreset = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: theme,
      dark: theme,
    },
  },
});

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "SistemaFab";

  constructor(private config: PrimeNGConfig) {
    this.config.theme.set({
      preset: CustomPreset,
      options: {
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primeng",
          order: "tailwind-base, primeng, tailwind-utilities",
        },
      },
    });
  }
}
