import { Component, Input, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Person } from "src/app/models/person";
import { ApiPersonsService } from "src/app/services/api-persons.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

import { ImageDialogComponent } from "../image-dialog/image-dialog.component";

@Component({
  selector: "student-file",
  templateUrl: "./student-file.component.html",
  styleUrls: ["./student-file.component.scss"],
})
export class StudentFileComponent implements OnInit {
  @Input() personId: number;

  personFile: Person;

  constructor(
    private APIPersons: ApiPersonsService,
    private ui: UserInterfaceService,
    private imageDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getFile();
  }

  getFile() {
    this.ui.spin$.next(true);
    this.APIPersons.getById(this.personId).subscribe({
      next: (response) => {
        this.personFile = response.data;
        this.ui.spin$.next(false);
      },
    });
  }

  showImage(title: string, url: string) {
    this.imageDialog.open(ImageDialogComponent, {
      data: {
        title: title,
        url: url,
        printable: false,
      },
    });
  }
}
