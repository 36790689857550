import { Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertDialogComponent } from "src/app/components/alert-dialog/alert-dialog.component";
import { UserProfilesEnum } from "src/app/enums/user-profiles-enum";
import { ElementsAlertDialog } from "src/app/models/elements-alert-dialog";
import { StudentCourse } from "src/app/models/student-course";
import { ApiStudentsService } from "src/app/services/api-students.service";
import { IdentityService } from "src/app/services/identity.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

@Component({
  selector: "app-student-course-details",
  templateUrl: "./student-course-details.component.html",
  styleUrls: ["./student-course-details.component.scss"],
})
export class StudentCourseDetailsComponent implements OnInit {
  title: string = "Curso";
  courseId: number;
  personId: number;
  studentId: number;
  dataLoaded: boolean = false;

  canAddReceipt: boolean = true;
  showAddReceiptButton: boolean = true;

  course: StudentCourse = new StudentCourse();
  allProfiles: number[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private identity: IdentityService,
    private APIStudents: ApiStudentsService,
    private ui: UserInterfaceService,
    private alertDialog: MatDialog,
  ) {
    this.allProfiles = this.identity.getAllProfiles;

    this.route.params.subscribe((params) => {
      this.courseId = params["courseId"];
      this.personId = params["personId"]
        ? params["personId"]
        : this.identity.getDecodedToken().id_person;
      this.studentId = params["studentId"]
        ? params["studentId"]
        : this.identity.getDecodedToken().id_student;

      if (
        this.allProfiles.includes(UserProfilesEnum.USER) ||
        this.allProfiles.includes(UserProfilesEnum.TREASURY)
      ) {
        this.showAddReceiptButton = true;
      } else {
        this.showAddReceiptButton = false;
      }

      this.getStudentCourseDetails();
    });
  }

  ngOnInit(): void {}

  getStudentCourseDetails() {
    this.ui.spin$.next(true);
    this.APIStudents.getStudentCourses(this.studentId, this.courseId).subscribe(
      (response) => {
        this.course = response.data[0];
        this.dataLoaded = true;
        this.ui.spin$.next(false);
      },
    );
  }

  setCanAddReceipt($event: boolean) {
    this.canAddReceipt = !$event;
  }

  goToAddPayment() {
    if (!this.canAddReceipt) {
      const dialogElements: ElementsAlertDialog = {
        title: "Agregar comprobante",
        content:
          "No es posible agregar un nuevo comprobante ya que tiene uno a la espera de aprobación.",
        status: "warning",
        closeButtonLabel: "Cerrar",
      };

      const dialog = this.alertDialog.open(AlertDialogComponent, {
        // width: '50%',
        data: dialogElements,
      });
    } else {
      this.router.navigate(["add-payment", this.courseId, this.personId]);
    }
  }
}
