import { Component, Inject, OnInit } from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { LicenseHorizontalComponent } from "src/app/components/license-horizontal/license-horizontal.component";
import { License } from "src/app/models/license";
import { LicenseType } from "src/app/models/license-type";
import { Person } from "src/app/models/person";
import { ApiLicensesService } from "src/app/services/api-licenses.service";
import { ApiPersonsService } from "src/app/services/api-persons.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

import { AlertDialogComponent } from "../alert-dialog/alert-dialog.component";

@Component({
  selector: "license-create-dialog",
  templateUrl: "./license-create-dialog.component.html",
  styleUrls: ["./license-create-dialog.component.scss"],
})
export class LicenseCreateDialogComponent implements OnInit {
  person: Person;
  personLicenses: License[];
  licenseCreateForm: FormGroup;
  enabled: boolean = true;
  type: LicenseType;
  valid_until: Date;
  licenseTypes: LicenseType[] = [];
  newLicense: License = new License();
  dialogRef: MatDialogRef<LicenseHorizontalComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; person: Person },
    private APILicenses: ApiLicensesService,
    private APIPersons: ApiPersonsService,
    private ui: UserInterfaceService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private disableAmateurdialog: MatDialog,
  ) {
    const currentDate = new Date();

    this.licenseCreateForm = this.fb.group({
      type: [null, Validators.required],
      person: [this.data.person, Validators.required],
      valid_until: [this.valid_until, Validators.required],
    });
  }

  ngOnInit(): void {
    this.personLicenses = this.getLicensesByPersonId(this.data.person.id);
    this.person = this.data.person;
    this.getLicenseTypes();
  }

  getLicenseTypes() {
    this.ui.spin$.next(true);
    this.APILicenses.getTypes().subscribe((response) => {
      this.licenseTypes = response.data;
      this.ui.spin$.next(false);
    });
  }

  getPersonById(id_person: number) {
    this.ui.spin$.next(true);
    this.APIPersons.getById(id_person).subscribe((response) => {
      this.person = response.data;
      this.ui.spin$.next(false);
    });
  }

  getLicensesByPersonId(id_person: number) {
    this.APILicenses.getLicensesByPersonId(id_person).subscribe((response) => {
      this.personLicenses = response.data;
      console.log("personLicenses", response.data);
    });
    return this.personLicenses;
  }

  hasAmateurLicenseEnabled() {
    let hasAmateurLicense = false;
    this.personLicenses.forEach((license) => {
      if (license.type == "amateur" && license.enabled) {
        hasAmateurLicense = true;
      }
    });
    return hasAmateurLicense;
  }

  disableAmateurLicense() {
    this.personLicenses.forEach((license) => {
      if (license.type == "amateur" && license.enabled) {
        license.enabled = false;
        this.APILicenses.update(license).subscribe((response) => {
          if (response.status == 200) {
            alert("Licencia amateur desactivada correctamente");
          } else {
            alert("Error al desactivar la licencia amateur");
          }
        });
      }
    });
  }

  createLicense() {
    this.ui.spin$.next(true);
    this.newLicense["id"] = 0;
    this.newLicense["enabled"] = this.enabled;
    this.newLicense["id_person"] = this.person.id;
    this.newLicense["type"] = this.licenseCreateForm.value.type;
    this.newLicense["valid_until"] = this.licenseCreateForm.value.valid_until;

    const license_type = this.licenseTypes.find(
      (type) => type.code == this.newLicense["type"],
    );

    this.APILicenses.create(this.newLicense).subscribe((response) => {
      // this.ui.openSnackBar(response.message);
      console.log("response al crear licencia", response);
      if (response.status == 201) {
        alert(`Licencia ${license_type?.name} creada correctamente`);
        // } else if (response.status == 200 && response.code == 200) {
        //   alert("Licencia Modificada correctamente");
      } else if (response.status == 200 && response.code == 409) {
        alert(response.msg);
      } else {
        alert("Error al crear la licencia: " + response.msg);
      }

      this.ui.spin$.next(false);

      this.router.navigate(["licenses-list"]);
    });
  }

  crearLicencia() {
    if (this.licenseCreateForm.valid) {
      if (
        this.licenseCreateForm.value.type == "professional" &&
        this.hasAmateurLicenseEnabled()
      ) {
        const dialogRef = this.disableAmateurdialog.open(AlertDialogComponent, {
          data: {
            title: "Licencia amateur activa",
            content:
              "El usuario tiene una licencia amateur activa, al activar una licencia profesional se desactivará la licencia amateur",
            okButtonLabel: "Proceder",
            closeButtonLabel: "Cancelar",
          },
        });
        dialogRef.afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.createLicense();
            this.disableAmateurLicense();
          }
        });
        return;
      }

      this.createLicense();
    }
  }
}
