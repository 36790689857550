<div class="containers container-user-validation">
  <div style="max-width: 90%; flex: 1 1 100%; box-sizing: border-box">
    <h1>Validación de usuario</h1>
    <mat-card>
      <h3>{{ validationMsg }}</h3>
      <h3>
        Presione el siguiente botón para regresar al
        <strong>Inicio de Sesión</strong>.
      </h3>
      <div class="container-user-validation">
        <button
          mat-flat-button
          color="link"
          type="button"
          (click)="backToLogin()"
        >
          <mat-icon>chevron_left</mat-icon> Volver a Login
        </button>
      </div>
    </mat-card>
  </div>
</div>
