import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { HttpBaseResponse } from "../models/base-response";
import { ApiBaseService } from "./api-base.service";

@Injectable({
  providedIn: "root",
})
export class ApiEnrollmentStatusService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "enrollmentstatus";
  }

  /**
   * @ngdoc function
   * @name getAll
   * @param statusId number
   * @methodOf ApiStatesService
   * @description Se obtiene la información de los estados de inscripción.
   * @return HttpBaseResponse
   */

  getAll(statusId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (statusId) endpoint += `/${statusId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }
}
