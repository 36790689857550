import { Injectable } from "@angular/core";

import { PaymentStatusEnum } from "../enums/payment-status-enum";
import { ReceiptStatusEnum } from "../enums/receipt-status-enum";
import { SemaphoreColumn } from "../models/semaphore-column";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}

  receiptFileValidation(type: string) {
    let valid: boolean = false;

    switch (type) {
      case "image/jpeg":
      case "image/png":
      case "application/pdf":
        valid = true;
        break;
    }

    return valid;
  }

  userFileValidation(type: string) {
    let valid: boolean = false;

    switch (type) {
      case "image/jpeg":
      case "image/png":
        valid = true;
        break;
    }

    return valid;
  }

  isExpired(date: Date | string): boolean {
    if (typeof date === "string") {
      date = new Date(date);
    }
    return new Date() > date;
  }

  ifSingleFee(
    fees: string | number,
    fee_price: number,
    enrollment_price: number,
  ) {
    const fees_schema = {
      fees: fees,
      fee_price: fee_price,
    };

    if (fees == 0) {
      fees_schema.fees = "Única";
      fees_schema.fee_price = enrollment_price;
    }
    return fees_schema;
  }

  getValidEnabledBooleanSemaphore(enabled: boolean | string | undefined): any {
    let color: string = "#f6bd40";
    let icon: string = "warning";
    if (enabled == true) {
      // enabled_status_name = "Habilitada";
      color = "#8AC926"; // GREEN
      icon = "check";
    } else {
      // enabled_status_name = "Deshabilitada";
      color = "#F44336"; // RED
      icon = "close";
    }

    return {
      value: enabled,
      icon: icon,
      color: color,
      background: "transparent",
    };
  }

  getReceiptStatusSemaphore(receipt_status: number): any {
    let color: string = "lightgray";
    let status: string = "En Aprobación";

    if (receipt_status) {
      switch (receipt_status?.toString()) {
        case ReceiptStatusEnum.APPROVED.toString():
          color = "#8ac926";
          status = "Aprobado";
          break;
        case ReceiptStatusEnum.REJECTED.toString():
          color = "#fd7a7e";
          status = "Rechazado";
          break;
      }
    }

    const semaphore: SemaphoreColumn = {
      value: color,
      label: status,
    };

    return semaphore;
  }

  getPaymentStatusSemaphore(payment_status: number): any {
    let color: string = "#fd7a7e"; // RED

    switch (payment_status.toString()) {
      case PaymentStatusEnum.COMPLETE.toString():
        color = "#8ac926"; // GREEN
        status = "Pago Completo";
        break;
      case ReceiptStatusEnum.REJECTED.toString():
        color = "#fd7a7e"; // RED
        status = "Pago Rechazado";
        break;
      case PaymentStatusEnum.INCOMPLETE.toString():
        color = "#f6bd40"; // YELLOW
        status = "Pago Incompleto";
        break;
    }
    const semaphore: SemaphoreColumn = {
      value: color,
      label: status,
    };
    return semaphore;
  }

  getValidLicenseStatusSemaphore(valid: boolean | string | undefined): any {
    let color: string = "#f6bd40";
    const icon: string = "warning";
    let status_name = "Warning";

    if (valid === "sin-configurar") {
      status_name = "Sin Configurar";
      color = "#a0a0a0"; // GREY
    } else if (valid) {
      status_name = "Vigente";
      color = "#8ac926"; // GREEN
    } else {
      status_name = "Vencida";
      color = "#fd7a7e"; // RED
    }

    return {
      value: color,
      label: status_name,
    };
  }

  getValidCredentialStatusSemaphore(valid: boolean | string | undefined): any {
    let color: string = "#f6bd40";
    const icon: string = "warning";
    let status_name = "Warning";

    if (valid === "sin-configurar") {
      status_name = "Sin Configurar";
      color = "#a0a0a0"; // GREY
    } else if (valid) {
      status_name = "Vigente";
      color = "#8ac926"; // GREEN
    } else {
      status_name = "Vencida";
      color = "#fd7a7e"; // RED
    }

    return {
      value: color,
      label: status_name,
    };
  }

  getBoutResultSemaphore(bout_result: string, bout_method: string): any {
    let color: string = "#8ac926"; // GREEN;
    let status_name = "W";

    switch (bout_result) {
      case "L":
        color = "#fd7a7e"; // RED
        status_name = "Perdedor";
        break;
      case "D":
        color = "#8DCBE4"; // SKYBLUE
        status_name = "Empate";
        break;
    }

    return {
      value: color,
      label: status_name + "-" + bout_method,
    };
  }
}
