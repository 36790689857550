import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "requests-list-table",
  templateUrl: "./requests-list-table.component.html",
  styleUrls: ["./requests-list-table.component.scss"],
})
export class RequestsListTableComponent implements OnInit {
  @Input() requests: any[] = [];
  @Output() onClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  onClickRequest(request: any) {
    this.onClick.emit(request);
  }
}
