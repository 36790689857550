import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { CourseDetailsDialogComponent } from "src/app/components/course-details-dialog/course-details-dialog.component";
import { CustomTableColumnType } from "src/app/components/custom-table/enums/custom-table-column-type.enum";
import { CustomTableColumn } from "src/app/components/custom-table/interfaces/custom-table-column";
import { CustomTableGenericButton } from "src/app/components/custom-table/interfaces/custom-table-generic-button";
import { CustomTableStructure } from "src/app/components/custom-table/interfaces/custom-table-structure";
import { CourseListItem } from "src/app/models/course-list-item";
import { StudentCourse } from "src/app/models/student-course";
import { StudentCoursetListItem } from "src/app/models/student-course-list-item";
import { ApiCoursesService } from "src/app/services/api-courses.service";
import { ApiStudentsService } from "src/app/services/api-students.service";
import { IdentityService } from "src/app/services/identity.service";
import { UtilsService } from "src/app/services/utils.service";

@Component({
  selector: "app-student-courses",
  templateUrl: "./student-courses.component.html",
  styleUrls: ["./student-courses.component.scss"],
})
export class StudentCoursesComponent implements OnInit {
  dataLoadedSC: boolean = false;
  dataLoadedAC: boolean = false;
  availableCourses: boolean = true;

  studentCourses: StudentCourse[] = [];
  itemList: StudentCoursetListItem[] = [];
  courses: CourseListItem[] = [];

  enrollBtn: CustomTableGenericButton = {
    icon: "info",
    text: "Más información",
    color: "primary",
  };

  columnsSC: CustomTableColumn[] = [
    { name: "id", text: "Id", type: CustomTableColumnType.TEXT },
    { name: "name", text: "Curso", type: CustomTableColumnType.TEXT },
    {
      name: "enrollment_date",
      text: "Fecha inscripción",
      type: CustomTableColumnType.DATE,
    },
    {
      name: "last_payment_date",
      text: "Fecha último pago",
      type: CustomTableColumnType.DATE,
    },
    {
      name: "payment_status_semaphore",
      text: "Estado última cuota",
      type: CustomTableColumnType.SEMAPHORE,
    },
    {
      name: "last_payment_fee",
      text: "Cuotas abonadas",
      type: CustomTableColumnType.TEXT,
    },
  ];

  columnsAC: CustomTableColumn[] = [
    { name: "id", text: "Id", type: CustomTableColumnType.TEXT },
    { name: "name", text: "Nombre", type: CustomTableColumnType.TEXT },
    {
      name: "vacancies_enrollments",
      text: "Cubiertas / Vacantes",
      type: CustomTableColumnType.TEXT,
    },
    { name: "dates", text: "Fechas", type: CustomTableColumnType.TEXT },
    { name: "fees", text: "Cuotas", type: CustomTableColumnType.TEXT },
    {
      name: "fee_price",
      text: "Precio por cuota",
      type: CustomTableColumnType.TEXT,
    },
    {
      name: "enrollment_enabled",
      text: "Abierto",
      type: CustomTableColumnType.BOOLEAN_SEMAPHORE,
    },
  ];

  tableSC: CustomTableStructure = {
    columns: this.columnsSC,
    data: this.itemList,
  };
  tableAC: CustomTableStructure = {
    columns: this.columnsAC,
    data: this.courses,
  };

  constructor(
    private APIStudents: ApiStudentsService,
    private identity: IdentityService,
    private changeRef: ChangeDetectorRef,
    private router: Router,
    private APICourses: ApiCoursesService,
    private courseDetailsDialog: MatDialog,
    private utils: UtilsService,
  ) {}

  ngOnInit(): void {
    this.getStudentCourses();
    this.getAllCourses();
  }

  getAllCourses() {
    this.APICourses.getList().subscribe((response) => {
      if (response.data.length < 1) this.availableCourses = false;
      this.tableAC.data = response.data.map((course: CourseListItem) => {
        return {
          ...course,
          enrollment_enabled: this.utils.getValidEnabledBooleanSemaphore(
            course.enrollment_enabled,
          ),
          fees: this.utils.ifSingleFee(
            course.fees,
            course.fee_price,
            course.enrollment_price,
          )?.fees,
          fee_price: this.utils.ifSingleFee(
            course.fees,
            course.fee_price,
            course.enrollment_price,
          )?.fee_price,
        };
      });

      this.tableAC = { columns: this.columnsAC, data: this.tableAC.data };
      this.dataLoadedAC = true;
    });
  }

  getStudentCourses() {
    if (this.identity.getDecodedToken().id_student) {
      this.APIStudents.getStudentCourses(
        this.identity.getDecodedToken().id_student,
      ).subscribe((response) => {
        this.studentCourses = response.data;

        this.studentCourses.forEach((sc) => {
          const last_payment_fee =
            sc.last_payment_fee.toString() != ""
              ? sc.last_payment_fee.toString() + " de " + sc.fees.toString()
              : "-";
          this.itemList.push({
            id: sc.id,
            name: sc.name,
            enrollment_date: sc.enrollment_date,
            last_payment_date: sc.last_payment_date,
            last_payment_fee: last_payment_fee,
            payment_status_semaphore: this.utils.getPaymentStatusSemaphore(
              sc.payment_status_fee,
            ),
          });
        });

        this.tableSC = { columns: this.columnsSC, data: this.itemList };
        this.changeRef.detectChanges();
        this.dataLoadedSC = true;
      });
    } else {
      this.tableSC = { columns: this.columnsSC, data: this.itemList };
      this.changeRef.detectChanges();
      this.dataLoadedSC = true;
    }
  }

  goToCourseDetails(event: number) {
    this.router.navigate(["student-course-details", event]);
  }

  showMoreInfo(courseId: number) {
    this.APICourses.getAll(courseId).subscribe((response) => {
      this.courseDetailsDialog.open(CourseDetailsDialogComponent, {
        width: "90%",
        maxWidth: "800px",
        data: response.data,
      });
    });
  }

  new() {
    alert("Nuevo curso");
  }
}
