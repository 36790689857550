import { Component } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent {
  regForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
  ) {
    this.regForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      nationality: [null, Validators.required],
      identificationNumber: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9]\d*$/),
        ]),
      ],
      birthday: [null, Validators.required],
      address: [null, Validators.required],
      postalCode: [null, Validators.required],
      state: [null, Validators.required],
      city: [null, Validators.required],
      gender: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, Validators.required],
      password: [null, Validators.required],
      photo: [null, Validators.required],
      idPhotoFront: [null, Validators.required],
      idPhotoBack: [null, Validators.required],
    });
  }

  register(): void {
    alert("Thanks!");
  }

  backToLogin() {
    this.router.navigate(["login"]);
  }
}
