import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Router } from "@angular/router";
import { ReceiptListItem } from "src/app/models/receipt-list-item";
import { ApiPaymentsService } from "src/app/services/api-payments.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

import { AlertDialogComponent } from "../alert-dialog/alert-dialog.component";
import { CustomTableColumnType } from "../custom-table/enums/custom-table-column-type.enum";
import { CustomTableColumn } from "../custom-table/interfaces/custom-table-column";
import { CustomTableGenericButton } from "../custom-table/interfaces/custom-table-generic-button";
import { CustomTableStructure } from "../custom-table/interfaces/custom-table-structure";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";

@Component({
  selector: "receipts-by-status-table",
  templateUrl: "./receipts-by-status-table.component.html",
  styleUrls: ["./receipts-by-status-table.component.scss"],
})
export class ReceiptsByStatusTableComponent implements OnInit {
  @Input() status?: number;

  @Output() refreshData: EventEmitter<void> = new EventEmitter();

  receiptList: ReceiptListItem[] = [];

  dataLoaded: boolean = false;
  showReverseButton: boolean = false;
  showReceiptButton: boolean = false;
  showDetailsButton: boolean = false;

  tablePageIndex: number = 0;
  tablePageSize: number = 10;

  receiptBtn: CustomTableGenericButton = {
    icon: "receipt",
    text: "",
    title: "Ver comprobante",
    color: "primary",
  };
  reverseBtn: CustomTableGenericButton = {
    icon: "reply",
    text: "",
    title: "Reversar estado de comprobante",
    color: "warn",
  };

  columns: CustomTableColumn[] = [
    { name: "id", text: "N°", type: CustomTableColumnType.TEXT },
    { name: "receipt_date", text: "Fecha", type: CustomTableColumnType.DATE },
    { name: "course_name", text: "Curso", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
    { name: "person_name", text: "Usuario", type: CustomTableColumnType.TEXT },
    { name: "amount", text: "Monto", type: CustomTableColumnType.TEXT },
    { name: "comments", text: "Comentarios", type: CustomTableColumnType.TEXT },
    { name: "answer", text: "Respuesta", type: CustomTableColumnType.TEXT },
  ];

  columnsPendingReceipts: CustomTableColumn[] = [
    { name: "id", text: "N° Comprobante", type: CustomTableColumnType.TEXT },
    { name: "receipt_date", text: "Fecha", type: CustomTableColumnType.DATE },
    { name: "course_name", text: "Curso", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
    { name: "person_name", text: "Usuario", type: CustomTableColumnType.TEXT },
  ];

  tableData: CustomTableStructure;

  constructor(
    private router: Router,
    private APIPayments: ApiPaymentsService,
    private changeRef: ChangeDetectorRef,
    private imageDialog: MatDialog,
    private alertDialog: MatDialog,
    private ui: UserInterfaceService,
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem("receiptsByStatusTable.pageIndex"))
      this.tablePageIndex = parseInt(
        localStorage.getItem("receiptsByStatusTable.pageIndex")!,
      );

    if (localStorage.getItem("receiptsByStatusTable.pageSize"))
      this.tablePageSize = parseInt(
        localStorage.getItem("receiptsByStatusTable.pageSize")!,
      );

    this.getReceipts();
  }

  getReceipts() {
    // this.ui.spin$.next(true);
    this.APIPayments.getReceiptsByStatus(this.status).subscribe((response) => {
      this.receiptList = response.data;

      if (this.status) {
        this.showReverseButton = true;
        this.showReceiptButton = true;
        this.showDetailsButton = false;
        this.tableData = { columns: this.columns, data: this.receiptList };
      } else {
        this.showReverseButton = false;
        this.showReceiptButton = false;
        this.showDetailsButton = true;
        this.tableData = {
          columns: this.columnsPendingReceipts,
          data: this.receiptList,
        };
      }

      this.changeRef.detectChanges();
      this.dataLoaded = true;
      // this.ui.spin$.next(false);
    });
  }

  reverseReceiptStatus(receiptId: number) {
    const dialogRef = this.alertDialog.open(AlertDialogComponent, {
      width: "90%",
      maxWidth: "800px",
      data: {
        title: "Reversar estado de comprobante",
        content:
          "Esta operación vuelve hacia atrás el estado asignado al comprobante dejándolo nuevamente pendiente de tratamiento. ¿Desea continuar?",
        status: "warning",
        okButtonLabel: "Continuar",
        closeButtonLabel: "Cancelar",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.ui.spin$.next(true);
        this.APIPayments.reverseReceiptStatus(receiptId).subscribe(
          (response) => {
            this.ui.spin$.next(false);
            // this.getReceipts();
            this.refreshData.emit();
          },
        );
      }
    });
  }

  showReceipt(receipt: ReceiptListItem) {
    if (receipt.image?.split(".").pop() != "pdf") {
      this.imageDialog.open(ImageDialogComponent, {
        data: {
          title: "Comprobante #" + receipt.id,
          url: receipt.image,
          printable: true,
        },
      });
    } else {
      window.open(receipt.image, "_blank");
    }
  }

  goToReceiptDetails(event: any) {
    this.router.navigate(["payment-receipt-details", event]);
  }

  onPageChange(event: PageEvent) {
    localStorage.setItem(
      "receiptsByStatusTable.pageIndex",
      event.pageIndex.toString(),
    );
    localStorage.setItem(
      "receiptsByStatusTable.pageSize",
      event.pageSize.toString(),
    );
  }
}
