<div class="containers container-recordlist-header">
  <div
    style="
      max-height: 10%;
      flex: 1 1 100%;
      box-sizing: border-box;
      margin-bottom: 20px;
    "
  >
    <section-header
      title="Records"
      [showNewButton]="true"
      [newButtonText]="'Nuevo Record'"
      (newButtonPressed)="newRecord()"
    >
    </section-header>
  </div>

  <mat-card *ngIf="recordTypeList.length">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Legajos">
        <record-list-table [recordType]="'all'"></record-list-table>
      </mat-tab>
      <div *ngFor="let recordType of recordTypeList">
        <mat-tab label="{{ recordType.name }}">
          <record-list-table [recordType]="recordType.code"></record-list-table>
        </mat-tab>
      </div>
    </mat-tab-group>
  </mat-card>
</div>
