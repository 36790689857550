import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { HttpBaseResponse } from "../models/base-response";
import { RegistrationRequest } from "../models/registration-request";
import { ApiBaseService } from "./api-base.service";

@Injectable({
  providedIn: "root",
})
export class ApiChangeProfileRequestsService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "users";
  }

  /**
   * @ngdoc function
   * @name getAllChangeRequests
   * @methodOf ApiChangeProfileRequestsService
   * @description Se obtienen todas las solicitudes de cambio de datos.
   * @return HttpBaseResponse
   */

  getAllChangeRequests() {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/change_request/`,
    );
  }

  /**
   * @ngdoc function
   * @name getChangeRequest
   * @param id number
   * @methodOf ApiChangeProfileRequestsService
   * @description Se obtienen los ususarios según el estado enviado.
   * @return HttpBaseResponse
   */

  getChangeRequest(id: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/change_request/${id}/`,
    );
  }

  /**
   * @ngdoc function
   * @name updateStatusChangeRequest
   * @param id number
   * @param status string
   * @param email string
   * @methodOf ApiChangeProfileRequestsService
   * @description Se actualiza el estado de una solicitud de cambio de datos.
   * @return HttpBaseResponse
   */

  updateStatusChangeRequest(
    id: number,
    person_id: number,
    status: boolean,
    email: any,
    modified_values: any,
  ) {
    return this.http.patch<any>(
      `${this._apiUrl}/${this._controller}/change_request`,
      {
        id: id,
        person_id: person_id,
        status: status,
        email: email,
        modified_values: modified_values,
      },
    );
  }

  /**
   * @ngdoc function
   * @name createNewChangeRequest
   * @methodOf ApiChangeProfileRequestsService
   * @description Se crea una nueva solicitud de cambio de datos.
   * @return HttpBaseResponse
   */

  createNewChangeRequest(
    id_person: number,
    originalValues: Partial<RegistrationRequest>,
    modifiedValues: Partial<RegistrationRequest>,
  ) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/change_request`,
      {
        id_person: id_person,
        original_values: originalValues,
        modified_values: modifiedValues,
      },
    );
  }
}
