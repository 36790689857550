import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Course } from "src/app/models/course";
import { ApiCoursesService } from "src/app/services/api-courses.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

@Component({
  selector: "app-courses-form",
  templateUrl: "./courses-form.component.html",
  styleUrls: ["./courses-form.component.scss"],
})
export class CoursesFormComponent implements OnInit {
  title: string = "Nuevo Curso";
  enrollment_enabled: boolean = false;

  courseForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private APICourses: ApiCoursesService,
    private router: Router,
    private route: ActivatedRoute,
    private ui: UserInterfaceService,
  ) {
    this.courseForm = this.fb.group({
      id: [0],
      name: [null, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null, Validators.required],
      fees: [10, Validators.required],
      enrollment_price: [null, Validators.required],
      fee_price: [null, Validators.required],
      total_price: [{ value: null, disabled: true }, Validators.required],
      description: [null, Validators.required],
      day_time: [null, Validators.required],
      vacancies: [null, Validators.required],
      enrollment_enabled: null,
      enabled: null,
      enrollments: null,
    });

    this.route.params.subscribe((params) => {
      if (params["courseId"]) {
        this.courseForm.get("id")?.setValue(params["courseId"]);
        this.getCourse();
      }
    });
  }

  ngOnInit(): void {}

  getCourse() {
    this.ui.spin$.next(true);
    this.APICourses.getAll(this.courseForm.get("id")!.value).subscribe({
      next: (response) => {
        this.ui.spin$.next(false);
        this.enrollment_enabled = response.data.enrollment_enabled == 1;
        response.data.enrollment_enabled =
          response.data.enrollment_enabled == 1;
        this.courseForm.setValue(response.data);
      },
    });
  }

  save() {
    if (this.courseForm.valid) {
      let course: Course = new Course();
      course = this.courseForm.getRawValue();

      this.APICourses.set(course).subscribe((response) => {
        if (response.id_course) this.router.navigate(["courses-list"]);
      });
    }
    // else {
    //   Object.keys(this.courseForm.controls).forEach(field => {
    //     const control = this.courseForm.get(field)!;
    //     control.markAsTouched({ onlySelf: true });
    //   });
    //   console.log('CourseForm - Formulario No Válido');
    // }
  }

  updateTotalPrice(): void {
    this.courseForm
      .get("total_price")
      ?.setValue(
        this.courseForm.get("enrollment_price")?.value +
          this.courseForm.get("fee_price")?.value *
            this.courseForm.get("fees")?.value,
      );
  }
}
