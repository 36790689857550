import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiBaseService {
  _apiUrl: string = environment.apiUrl;
  _controller: string = "";
  // _httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };

  constructor() {}
}
