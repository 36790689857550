import { Component, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { AlertDialogComponent } from "src/app/components/alert-dialog/alert-dialog.component";
import { CredentialType } from "src/app/models/credential-type";
import { ApiCredentialTypeManagerService } from "src/app/services/api-credential-type-manager.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

@Component({
  selector: "app-credential-type-manager-dialog",
  templateUrl: "./credential-type-manager-dialog.component.html",
  styleUrls: ["./credential-type-manager-dialog.component.scss"],
})
export class CredentialTypeManagerDialogComponent {
  enrollmentForm: UntypedFormGroup;
  credentialTypeCreated = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; credentialType: CredentialType | null },
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    private fb: UntypedFormBuilder,
    private ui: UserInterfaceService,
    private APICredentialTypeManager: ApiCredentialTypeManagerService,
  ) {
    this.enrollmentForm = this.fb.group({
      enabled: [true, Validators.required],
      code: [{ value: null, disabled: true }, Validators.required],
      name: [null, Validators.required],
    });

    // Si no existe code, en un campo de solo lectura se vaya creando en el momeento segun el name pero sin  espacios
    // Si existe code, en un campo de solo lectura se muestre el code y nunca se cambia
    this.enrollmentForm.statusChanges.subscribe();
    if (this.data.credentialType) {
      this.enrollmentForm.patchValue(this.data.credentialType);
    } else {
      this.credentialTypeCreated = true;
      this.enrollmentForm.get("name")?.valueChanges.subscribe((value) => {
        const code = value
          .replace(/\s/g, "-")
          .replace(/[^a-zA-Z0-9-]/g, "")
          .toLowerCase();
        this.enrollmentForm.get("code")?.setValue(code);
      });
    }
  }

  handleEnabled(event: Event) {
    const value = (event.target as HTMLInputElement).value === "yes";
    this.enrollmentForm.get("enabled")?.setValue(value);
  }

  save(): void {
    if (this.enrollmentForm.valid) {
      this.ui.spin$.next(true);
      const regRequest: CredentialType = this.enrollmentForm.getRawValue();

      this.APICredentialTypeManager.saveCredentialType({
        created: this.credentialTypeCreated,
        ...regRequest,
      }).subscribe((response) => {
        if (response.status !== 200) return;
        this.ui.spin$.next(false);
        alert("Cargo actualizado correctamente");
        this.dialogRef.close();
      });
    } else {
      Object.keys(this.enrollmentForm.controls).forEach((field) => {
        const control = this.enrollmentForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
