import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { HttpBaseResponse } from "../models/base-response";
import { ApiBaseService } from "./api-base.service";

@Injectable({
  providedIn: "root",
})
export class ApiNationalitiesService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "nationalities";
  }

  /**
   * @ngdoc function
   * @name getAll
   * @param nationalityId number
   * @methodOf ApiNationalitiesService
   * @description Se obtiene la información de las nacionalidades.
   * @return HttpBaseResponse
   */

  getAll(nationalityId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (nationalityId) endpoint += `/${nationalityId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }
}
