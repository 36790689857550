import { HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// import { LoadingService } from '../service/loading.service';
import { finalize, tap } from "rxjs/operators";

import { IdentityService } from "../services/identity.service";

@Injectable()
export class AuthInterceptor {
  private count: number = 0;
  // constructor(
  //   private loading:LoadingService,
  //   private identityService :IdentityService){

  // }

  constructor(private identity: IdentityService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // let skipLoading = req.headers.get('skip');
    // if (!skipLoading) {
    //   this.loading.show();
    // }

    const token = this.identity.getToken();
    // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZF91c2VyIjoiMSIsImlkX3BlcnNvbiI6IjEiLCJpZF9wcm9maWxlIjoiMSJ9.7bvF9kTUpAJ21Vybb3z_Ry3mNWEcf5dTtC4MSHIIjOo";
    const request = !token
      ? req
      : req.clone({
          headers: req.headers
            .set("Authorization", `${token}`)
            .set("Content-Type", "application/json"),
        });

    this.count++;
    return next.handle(request).pipe(
      tap(
        () => {},
        (err) => {
          if (err.status === 401) {
            // if (!skipLoading) {
            //   this.loading.hide();
            // }
          }
          if (err.status === 403) {
          }
          // if (!skipLoading) {
          //   this.loading.hide();
          // }
        },
      ),
      finalize(() => {
        this.count--;
        if (this.count === 0) {
          // if (!skipLoading) {
          //   this.loading.hide();
          // }
        }
      }),
    );
  }
}
