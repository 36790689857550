import { Component, OnInit } from "@angular/core";
import { MenuItem, PrimeIcons, TreeNode } from "primeng/api";
import { SplitButton } from "primeng/splitbutton";

@Component({
  selector: "app-person-requests",
  templateUrl: "./person-requests.component.html",
  styleUrls: ["./person-requests.component.scss"],
})
export class PersonRequestsComponent implements OnInit {
  flowRequestContent: any | null = null;
  items: MenuItem[];
  flowLicence: TreeNode[] = [
    {
      key: "secretary-revision",
      label: "Revision de Secretaria",
      expanded: true,
      icon: PrimeIcons.FOLDER_OPEN,
      children: [
        {
          key: "secretary-rejected",
          label: "Rechazado",
          icon: PrimeIcons.TIMES,
        },
        {
          key: "professional-commission",
          label: "Comision Profesional",
          expanded: true,
          icon: PrimeIcons.BRIEFCASE,
          children: [
            {
              key: "professional-commission-rejected",
              label: "Rechazado",
              icon: PrimeIcons.TIMES,
            },
            {
              key: "treasury",
              label: "Tesoreria",
              expanded: true,
              icon: PrimeIcons.USER,
              children: [
                {
                  key: "treasury-rejected",
                  label: "Rechazado",
                  icon: PrimeIcons.TIMES,
                },
                {
                  key: "finish",
                  label: "Finalizado",
                  icon: PrimeIcons.CHECK,
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  allPendingRequests: any[] = [
    {
      title: "Licencia Profesional",
      status: {
        name: "Revision de Tesoreria",
        severity: "partial",
      },
      last_node: "treasury",
      created_at: "2024-08-05",
      updated_at: "2024-08-25",
    },
  ];

  allFinishRequests: any[] = [
    {
      title: "Licencia Profesional",
      status: {
        name: "Licencia emitida",
        severity: "success",
      },
      last_node: "finish",
      created_at: "2024-08-01",
      updated_at: "2024-08-13",
    },
    {
      title: "Licencia Profesional",
      status: {
        name: "Licencia rechazada",
        severity: "error",
      },
      last_node: "professional-commission-rejected",
      created_at: "2024-07-30",
      updated_at: "2024-08-01",
    },
    {
      title: "Licencia Amateur",
      status: {
        name: "Licencia emitida",
        severity: "success",
      },
      last_node: "finish",
      created_at: "2024-07-29",
      updated_at: "2024-08-01",
    },
  ];

  constructor() {
    this.items = [
      {
        label: "Licencia Amateur",
        routerLink: ["/amateur-license-form"],
      },
      {
        label: "Licencia Profesional",
        routerLink: ["/el-form-professional"],
      },
    ];
  }

  ngOnInit() {}

  allRequestsWithoutFilter(): any[] {
    return this.allPendingRequests.concat(this.allFinishRequests);
  }

  showFlowRequestDialog(request: any) {
    this.flowRequestContent = request;
  }

  showMenuOptions(event: SplitButton, eventClick: MouseEvent): void {
    setTimeout(() => {
      event.onDropdownButtonClick(eventClick);
    }, 100);
  }
}
