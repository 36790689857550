<div
  class="containers"
  style="
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    place-content: stretch space-around;
    align-items: stretch;
  "
>
  <div style="max-width: 90%; flex: 1 1 100%; box-sizing: border-box">
    <h1>{{ title }}</h1>
    <mat-card>
      <registration-form [personId]="personId"></registration-form>
    </mat-card>
  </div>
</div>
