<div style="display: flex; flex-direction: row; box-sizing: border-box">
  <h1 style="align-self: center; flex: 1 1 0%; box-sizing: border-box">
    {{ title }}
  </h1>
  <button
    *ngIf="showNewButton"
    mat-flat-button
    color="primary"
    style="align-self: center"
    (click)="onClickNewButton()"
  >
    <mat-icon>add</mat-icon> {{ newButtonText || "NUEVO" }}
  </button>
</div>
