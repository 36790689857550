import { Component, Input, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { CredentialDialogComponent } from "src/app/components/credential-dialog/credential-dialog.component";
import { CustomTableColumnType } from "src/app/components/custom-table/enums/custom-table-column-type.enum";
import { CustomTableColumn } from "src/app/components/custom-table/interfaces/custom-table-column";
import { CustomTableGenericButton } from "src/app/components/custom-table/interfaces/custom-table-generic-button";
import { CustomTableOptions } from "src/app/components/custom-table/interfaces/custom-table-options";
import { CustomTableStructure } from "src/app/components/custom-table/interfaces/custom-table-structure";
import { Credential } from "src/app/models/credential";
import { Person } from "src/app/models/person";
import { ApiCredentialsService } from "src/app/services/api-credentials.service";
import { ApiPersonsService } from "src/app/services/api-persons.service";
import { UtilsService } from "src/app/services/utils.service";

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: "credential-list-table",
  templateUrl: "./credential-list-table.component.html",
  styleUrls: ["./credential-list-table.component.scss"],
})
export class CredentialListTableComponent implements OnInit {
  today: Date = new Date();
  @Input() credentialType: string = "";
  @Input() label: string = "Credentials";

  allPersonListCols: CustomTableColumn[] = [
    { name: "id_person", text: "Legajo", type: CustomTableColumnType.TEXT },
    { name: "firstname", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "lastname", text: "Apellido", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
  ];

  credentialListCols: CustomTableColumn[] = [
    { name: "id", text: "ID", type: CustomTableColumnType.TEXT },
    { name: "id_person", text: "Legajo", type: CustomTableColumnType.TEXT },
    { name: "firstname", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "lastname", text: "Apellido", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
    {
      name: "valid_until",
      text: "Vencimiento",
      type: CustomTableColumnType.DATE,
    },
    { name: "valid", text: "Vigencia", type: CustomTableColumnType.SEMAPHORE },
    {
      name: "enabled",
      text: "Habilitada",
      type: CustomTableColumnType.BOOLEAN_SEMAPHORE,
    },
  ];
  createCredentialButton: CustomTableGenericButton = {
    icon: "assignment_ind",
    text: "Gestionar",
    color: "primary",
    title: "Crear Credencial",
  };

  allPersonListTableOptions: CustomTableOptions = {
    filtering: true,
    sorting: false,
  };

  credentialListTableOptions: CustomTableOptions = {
    filtering: true,
    sorting: false,
  };

  allPersonListTableStructure: CustomTableStructure = {
    columns: this.allPersonListCols,
    data: [],
    options: this.allPersonListTableOptions,
  };

  credentialListTableStructure: CustomTableStructure = {
    columns: this.credentialListCols,
    data: [],
    options: this.credentialListTableOptions,
  };

  selected_id_person: number = 0;
  selected_identity_number: string = "";

  constructor(
    private utils: UtilsService,
    private APICredentials: ApiCredentialsService,
    private APIPersons: ApiPersonsService,
    private credentialDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllPersonData();
    this.getAllCredentialsWithPersonData(this.credentialType);
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  generateColumns(): any[] {
    const credentialKeys = Object.keys(new Credential() as Credential);
    console.log("Credential keys", credentialKeys);
    return credentialKeys.map((key) => ({
      field: key,
      header: this.capitalizeFirstLetter(key),
    }));
  }

  getAllPersonData() {
    this.APICredentials.getAllWithPersonData("all").subscribe((response) => {
      console.log(`getAllWithPersonData[${"allPerson"}]: `, response.data);

      this.allPersonListTableStructure.data = response.data;
    });
  }

  getAllCredentialsWithPersonData(credentialType: string) {
    this.APICredentials.getAllWithPersonData(credentialType).subscribe(
      (response) => {
        console.log(`getAllWithPersonData[${credentialType}]: `, response.data);

        this.credentialListTableStructure.data = response.data.map(
          (credential: Credential) => {
            return {
              ...credential,
              valid: this.utils.getValidCredentialStatusSemaphore(
                credential.valid,
              ),
              enabled: this.utils.getValidEnabledBooleanSemaphore(
                credential.enabled,
              ),
            };
          },
        );
      },
    );
  }

  openCreateCredentialDialog(id_person: number) {
    //get person data
    let person: Person;
    this.APIPersons.getById(id_person).subscribe((response) => {
      person = response.data;
      console.log("Person", person);
      const dialogRef = this.credentialDialog.open(CredentialDialogComponent, {
        width: "90%",
        maxWidth: "800px",
        data: {
          title: `Crear Credencial para: ${person.firstname} ${person.lastname}`,
          personId: person.id,
        },
      });
    });
  }

  openEditCredentialDialog(id_credential: number) {
    console.log("openEditCredentialDialogCredentialId", id_credential);
    // get person data
    this.APICredentials.getPersonIdByCredentialId(id_credential).subscribe(
      (response) => {
        this.selected_id_person = response.data.id_person;
        console.log("selected_id_person", this.selected_id_person);

        this.APICredentials.getAllWithPersonData(
          this.credentialType,
          this.selected_id_person,
        ).subscribe((response2) => {
          console.log("responseAlWIthPersonData", response2);
          this.selected_identity_number = response2.data[0]?.identity_number;

          const dialogRef = this.credentialDialog.open(
            CredentialDialogComponent,
            {
              width: "90%",
              maxWidth: "800px",
              data: {
                title: `Editar Credencial N° ${this.selected_identity_number}`,
                credential: response2.data,
                personId: this.selected_id_person,
                creatingCredential: false,
              },
            },
          );
        });
      },
    );
  }

  isExpired(date: string): boolean {
    return this.utils.isExpired(date);
  }

  delete(id: number): void {
    console.log("Delete credential", id);
  }

  modifyCredential(credential: Credential): void {
    console.log("Modify credential", credential);
  }
}
