<h2 mat-dialog-title>Firmar tu solicitud</h2>
<div mat-dialog-content>
  <canvas #canvas width="400" height="300"></canvas>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button (click)="onClickCancel()">Cerrar</button>
  <button
    mat-button
    *ngIf="showClearButton"
    (click)="onClickReset()"
    color="warn"
  >
    Reintetar
  </button>
  <button
    mat-button
    (click)="onClickOk()"
    color="primary"
    [disabled]="!showClearButton"
  >
    Enviar
  </button>
</div>
