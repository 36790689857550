import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { License } from "src/app/models/license";

import { HttpBaseResponse } from "../models/base-response";
import { ApiBaseService } from "./api-base.service";

@Injectable({
  providedIn: "root",
})
export class ApiLicensesService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "licenses";
  }

  /**
   * @ngdoc function
   * @name getAll
   * @param personId number
   * @methodOf ApiLicensesService
   * @description Se obtiene la información de las credenciales.
   * @return HttpBaseResponse
   */

  getAll(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getAllByTypes(code: string) {
    const endpoint = `${this._apiUrl}/${this._controller}/${code}`;
    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getAllProfesional(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/profesional`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getAllAmateur(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/amateur`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getPersonIdByLicenseId(licenseId: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/get_person_id_by_license_id/${licenseId}`,
    );
  }

  getLicensesByPersonId(personId: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/by_person_id/${personId}`,
    );
  }

  getAllWithPersonData(licenseType: string, personId?: number) {
    if (licenseType === "no-license") {
      return this.http.get<HttpBaseResponse>(
        `${this._apiUrl}/${this._controller}/with_person_data/all`,
      );
    }

    let endpoint = `${this._apiUrl}/${this._controller}/with_person_data/${licenseType}`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getTypes() {
    const endpoint = `${this._apiUrl}/${this._controller}/types`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  update(licenseData: License) {
    return this.http.patch<any>(
      `${this._apiUrl}/${this._controller}/form`,
      licenseData,
    );
  }

  create(licenseData: License) {
    console.log("licenseData", licenseData);
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/form`,
      licenseData,
    );
  }
}
