<div
  class="container-credential"
  [ngClass]="{
    disabled: !['valid', 'expired'].includes(licenseStatus),
    expired: licenseStatus === 'expired',
  }"
>
  <div class="background-credential">
    <div
      class="flex flex-row w-full h-full justify-center items-center relative"
    >
      <div class="flex overflow-auto licence-size">
        <div class="w-1/3 flex flex-col">
          <div class="h-4/6" [ngClass]="{ 'bg-red-400': seccionateBackground }">
            <img
              [src]="userData.photo"
              class="h-full w-full p-3 object-cover rounded-2xl rounded-tl-3xl rounded-br-3xl"
            />
          </div>
          <div
            class="h-1/6 flex flex-col items-center justify-start"
            [ngClass]="{ 'bg-green-400': seccionateBackground }"
          >
            <span class="text-sm font-medium">Licencia N°</span>
            <span class="text-2xl font-semibold leading-5">{{
              userData.identityNumber
            }}</span>
          </div>
          <div
            class="h-1/6 flex justify-center p-1.5"
            [ngClass]="{ 'bg-blue-400': seccionateBackground }"
          >
            <img
              src="assets/images/logoFooterFAB.webp"
              class="text-neutral-300 w-full"
            />
          </div>
        </div>
        <div class="w-2/3">
          <div
            class="h-1/6 p-1"
            [ngClass]="{ 'bg-yellow-400': seccionateBackground }"
          >
            <div class="flex flex-row items-center">
              <lottie-component class="w-16" />
              <span class="text-xl font-bold tracking-tighter uppercase">{{
                "Licencia de boxeador/a " + licenseData.type
              }}</span>
            </div>
          </div>
          <div
            class="h-4/6 p-7"
            [ngClass]="{ 'bg-orange-400': seccionateBackground }"
          >
            <div class="flex flex-row h-full">
              <div
                class="flex flex-col justify-around pr-3 text-lg font-medium tracking-tight"
                [ngClass]="{ 'bg-orange-500': seccionateBackground }"
              >
                <p>Nombre y Apellido:</p>
                <p>Fecha Nacimiento:</p>
                <p>Localidad:</p>
                <p>Provincia:</p>
              </div>
              <div
                class="flex flex-col justify-around pl-3 text-lg font-medium tracking-tight"
                [ngClass]="{ 'bg-orange-600': seccionateBackground }"
              >
                <p>{{ userData.fullName }}</p>
                <p>{{ userData.birthdate }}</p>
                <p>{{ userData.city_name }}</p>
                <p>{{ userData.state_name }}</p>
              </div>
            </div>
          </div>
          <div
            class="h-1/6 flex flex-col justify-center px-3"
            [ngClass]="{ 'bg-purple-400': seccionateBackground }"
          >
            <div class="flex flex-row justify-between">
              <div
                class="flex flex-col justify-around"
                [ngClass]="{ 'bg-purple-500': seccionateBackground }"
              >
                <span class="text-2xl font-medium">Vigente hasta:</span>
              </div>
              <div
                class="flex flex-col justify-around"
                [ngClass]="{ 'bg-purple-600': seccionateBackground }"
              >
                <span class="text-2xl font-semibold text-center text-nowrap">{{
                  formatExpiration(licenseData.valid_until)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <label
      class="expired-title title"
      [class.disabled-title]="!['valid', 'expired'].includes(licenseStatus)"
      >{{ licenseType.toUpperCase() }}</label
    >
  </div>
</div>
