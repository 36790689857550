<div class="containers container-login">
  <div class="flex-box">
    <img src="assets/images/logoFooterFAB.webp" />
    <mat-card>
      <form [formGroup]="loginForm" novalidate (ngSubmit)="login()">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="username" />
          <mat-error *ngIf="loginForm.get('username')?.hasError('required')">
            Este campo es <strong>obligatorio.</strong>
          </mat-error>
          <mat-error *ngIf="loginForm.get('username')?.hasError('pattern')">
            Ingrese una casilla de correo válida.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
            Este campo es <strong>obligatorio.</strong>
          </mat-error>
        </mat-form-field>

        <div class="login-buttons">
          <span class="spacer"></span>
          <button mat-raised-button color="primary" type="submit">
            Ingresar
          </button>
          <span class="spacer"></span>
        </div>
        <div class="login-buttons">
          <button
            type="button"
            mat-button
            color="link"
            (click)="goToForgotPassword()"
          >
            Olvidé mi contraseña
          </button>
          <button
            type="button"
            mat-button
            color="link"
            (click)="goToRegistration()"
          >
            Registrarme
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
