import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { AlertDialogComponent } from "src/app/components/alert-dialog/alert-dialog.component";
import { CoursesListDialogComponent } from "src/app/components/courses-list-dialog/courses-list-dialog.component";
import { CredentialDialogComponent } from "src/app/components/credential-dialog/credential-dialog.component";
import { CustomTableColumnType } from "src/app/components/custom-table/enums/custom-table-column-type.enum";
import { CustomTableColumn } from "src/app/components/custom-table/interfaces/custom-table-column";
import { CustomTableGenericButton } from "src/app/components/custom-table/interfaces/custom-table-generic-button";
import { CustomTableStructure } from "src/app/components/custom-table/interfaces/custom-table-structure";
import { EnrollmentDialogComponent } from "src/app/components/enrollment-dialog/enrollment-dialog.component";
import { EnrollmentStatusEnum } from "src/app/enums/enrollment-status-enum";
import { UserProfilesEnum } from "src/app/enums/user-profiles-enum";
import { PersonListItem } from "src/app/models/person-list-item";
import { StudentListItem } from "src/app/models/student-list-item";
import { ApiPersonsService } from "src/app/services/api-persons.service";
import { ApiStudentsService } from "src/app/services/api-students.service";
import { IdentityService } from "src/app/services/identity.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";
import { UtilsService } from "src/app/services/utils.service";

enum tabIndexEnum {
  ENROLLED = 0,
  PENDING = 1,
}

@Component({
  selector: "app-students-list",
  templateUrl: "./students-list.component.html",
  styleUrls: ["./students-list.component.scss"],
})
export class StudentsListComponent implements OnInit {
  allProfiles: number[] = [];

  studentList: StudentListItem[] = [];
  personsList: PersonListItem[] = [];

  dataLoadedALL: boolean = false;
  dataLoadedPE: boolean = false;

  approveEnrollBtn: CustomTableGenericButton = {
    icon: "check",
    text: "Validar Inscripción",
    color: "primary",
    title: "Validar",
  };
  enrollBtnIcon: CustomTableGenericButton = {
    icon: "assignment_turned_in",
    text: "",
    color: "primary",
  };
  addPaymentBtn: CustomTableGenericButton = {
    icon: "paid",
    text: "Agregar comprobante",
    color: "primary",
  };
  credentialBtn: CustomTableGenericButton = {
    icon: "contact_mail",
    text: "Credenciales",
    color: "primary",
  };

  enrollmentStatus: typeof EnrollmentStatusEnum = EnrollmentStatusEnum;
  userProfilesValues: typeof UserProfilesEnum = UserProfilesEnum;

  columnsPE: CustomTableColumn[] = [
    { name: "id", text: "Legajo", type: CustomTableColumnType.TEXT },
    { name: "person_name", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
    {
      name: "birthdate",
      text: "Fecha de Nac.",
      type: CustomTableColumnType.DATE,
    },
    { name: "state_name", text: "Provincia", type: CustomTableColumnType.TEXT },
    { name: "city_name", text: "Localidad", type: CustomTableColumnType.TEXT },
    {
      name: "active",
      text: "Correo Activado",
      type: CustomTableColumnType.BOOLEAN_SEMAPHORE,
    },
  ];

  tableAllStudents: CustomTableStructure;
  tablePendingEnrollments: CustomTableStructure;

  constructor(
    private router: Router,
    private APIStudents: ApiStudentsService,
    private APIPersons: ApiPersonsService,
    private changeRef: ChangeDetectorRef,
    private enrollmentDialog: MatDialog,
    private alertDialog: MatDialog,
    private identity: IdentityService,
    private ui: UserInterfaceService,
    private utils: UtilsService,
  ) {
    this.allProfiles = this.identity.getAllProfiles;
  }

  ngOnInit(): void {
    this.getPersons();
  }

  getPersons() {
    this.ui.spin$.next(true);
    this.APIPersons.getList().subscribe((response) => {
      this.personsList = response.data
        .map((p: PersonListItem) => {
          if (p.person_name.split(", ")[0] === "") {
            p.person_name = `(No especificado)`;
          }
          p.active = this.utils.getValidEnabledBooleanSemaphore(p.active);
          return p;
        })
        .sort((a: PersonListItem, b: PersonListItem) => {
          if (this.isSpecial(a) && !this.isSpecial(b)) {
            return 1;
          } else if (!this.isSpecial(a) && this.isSpecial(b)) {
            return -1;
          } else {
            return a.person_name.localeCompare(b.person_name);
          }
        });

      this.tableAllStudents = {
        columns: this.columnsPE,
        data: this.personsList,
      };
      this.changeRef.detectChanges();
      this.dataLoadedALL = true;
      this.ui.spin$.next(false);
    });
  }

  isSpecial(person: PersonListItem): boolean {
    return person.person_name === `(No especificado)`;
  }

  goToReceiptDetails(event: any) {
    console.log("goToReceiptDetails: ", event);
  }

  new() {
    this.router.navigate(["students-form"]);
  }

  goToEdit(studentId: number) {
    this.router.navigate(["students-form", studentId]);
  }

  showEnrollDialog(personId: number) {
    const userSelected = this.personsList.find((p) => p.id == personId);

    const dialogRef = this.enrollmentDialog.open(EnrollmentDialogComponent, {
      width: "90%",
      maxWidth: "800px",
      data: {
        title: "Asignar Inscripción",
        studentId: 0,
        personId: personId,
        studentName: userSelected?.person_name,
      },
    });

    dialogRef.afterClosed().subscribe((finished) => {
      if (finished) {
        this.getPersons();
      }
    });
  }

  showCourseListDialog(person: PersonListItem) {
    const dialogRef = this.enrollmentDialog.open(CoursesListDialogComponent, {
      width: "90%",
      maxWidth: "800px",
      data: {
        title: "Agregar comprobante",
        personId: person.id,
      },
    });

    // dialogRef.afterClosed().subscribe(finished => {
    //   if (finished) {
    //     console.log('Enroll OK');
    //     this.getPersons();
    //   }
    // });
  }

  showCredentialDialog(person: PersonListItem) {
    const dialogRef = this.enrollmentDialog.open(CredentialDialogComponent, {
      data: {
        title: "Modificar credenciales",
        personId: person.id,
      },
    });

    // dialogRef.afterClosed().subscribe(finished => {
    //   if (finished) {
    //     console.log('Enroll OK');
    //     this.getPersons();
    //   }
    // });
  }

  deleteStudent(person: any) {
    const dialogRef = this.alertDialog.open(AlertDialogComponent, {
      data: {
        title: "Eliminar Alumno",
        content: `Esta seguro de que desea eliminar al alumno ${person}?`,
        status: "danger",
        okButtonLabel: "Eliminar",
        closeButtonLabel: "Cancelar",
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        console.log(`NO Borre al estudiante con el id: ${person}`);
        this.getPersons();
      }
    });
  }
}
