import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";

@Component({
  selector: "app-student-file-dialog",
  templateUrl: "./student-file-dialog.component.html",
  styleUrls: ["./student-file-dialog.component.scss"],
})
export class StudentFileDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<StudentFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personId: number },
  ) {}

  ngOnInit(): void {}
}
