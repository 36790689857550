<div
  class="student-file-container"
  style="display: flex; flex-direction: column; box-sizing: border-box"
>
  <h2>{{ personFile.lastname }}, {{ personFile.firstname }}</h2>
  <mat-card style="margin-bottom: 15px">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >Nacionalidad</strong
        >
        <span>{{ personFile.nationality_name }}</span>
      </div>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >DNI</strong
        >
        <span>{{ personFile.identity_number }}</span>
      </div>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >Fecha de Nac.</strong
        >
        <span>{{ personFile.birthdate | date: "dd/MM/yyyy" }}</span>
      </div>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >Domicilio</strong
        >
        <span>{{ personFile.address }}</span>
      </div>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >CP</strong
        >
        <span>{{ personFile.postal_code }}</span>
      </div>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >Provincia</strong
        >
        <span>{{ personFile.state_name }}</span>
      </div>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >Localidad</strong
        >
        <span>{{ personFile.city_name }}</span>
      </div>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <strong style="max-width: 50%; flex: 1 1 100%; box-sizing: border-box"
          >Email</strong
        >
        <span
          ><a href="mailto:{{ personFile.email }}">{{
            personFile.email
          }}</a></span
        >
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <div style="display: flex; flex-direction: row; box-sizing: border-box">
        <span style="margin-right: 10px; flex: 1 1 0%; box-sizing: border-box">
          <h4>Foto</h4>
          <img
            class="user-images"
            [src]="personFile.photo"
            (click)="showImage('Foto', personFile.photo)"
          />
        </span>
        <span style="margin-right: 10px; flex: 1 1 0%; box-sizing: border-box">
          <h4>DNI Frente</h4>
          <img
            class="user-images"
            [src]="personFile.identity_card_front"
            (click)="showImage('DNI Frente', personFile.identity_card_front)"
          />
        </span>
        <span style="margin-right: 10px; flex: 1 1 0%; box-sizing: border-box">
          <h4>DNI Dorso</h4>
          <img
            class="user-images"
            [src]="personFile.identity_card_back"
            (click)="showImage('DNI Dorso', personFile.identity_card_back)"
          />
        </span>
      </div>
    </mat-card-content>
  </mat-card>
</div>
