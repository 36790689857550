<div class="containers container-courselist">
  <div
    style="
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      margin-bottom: 20px;
      place-content: center space-between;
      align-items: center;
    "
  >
    <section-header
      title="Reporte por cursos"
      [showNewButton]="false"
    ></section-header>
    <div class="file-buttons">
      <button
        mat-flat-button
        color="primary"
        style="margin: 5px 10px"
        (click)="exportExcelStudentCourses()"
      >
        <mat-icon>file_download</mat-icon> LISTADO ALUMNOS
      </button>
      <button
        mat-flat-button
        color="primary"
        style="margin: 5px 10px"
        (click)="exportPaymentsStatus()"
      >
        <mat-icon>file_download</mat-icon> ESTADOS DE PAGO
      </button>
    </div>
  </div>

  <div style="margin-right: 20px; flex: 1 1 100%; box-sizing: border-box">
    <mat-form-field
      class="by-course-filter"
      appearance="outline"
      style="width: 50%"
    >
      <mat-label>Filtrar por Curso</mat-label>
      <mat-select (valueChange)="onCourseChange($event)">
        <mat-option value="0">-- Todos --</mat-option>
        <mat-option *ngFor="let c of courses" [value]="c.id">{{
          "(" + c.id + ") " + c.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div style="flex: 1 1 1e-9px; box-sizing: border-box">
    <mat-card>
      <mat-card-content>
        <custom-table
          *ngIf="dataLoadedSC"
          [dataSource]="tableSC"
          [showActions]="true"
          [showGenericButton2]="true"
          (genericButton2Pressed)="goToDetails($event)"
          [genericButton2]="detailsBtn"
          [filterable]="true"
          genericButton2Title="Detalles de inscripción y pagos del usuario"
        >
        </custom-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
