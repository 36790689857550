<div
  class="container-credential"
  [class.disabled]="!['valid', 'expired'].includes(credentialStatus)"
  [class.expired]="credentialStatus === 'expired'"
  [class.only-view]="onlyView"
>
  <div class="background-credential">
    <div class="credential-content">
      <section class="credential-header">
        <lottie-component class="institution-logo" />

        <div class="d-flex flex-column datos title">
          <span>{{ userData.fullName }}</span>
          <span class="font-monospace text-uppercase fs-5 fw-normal range"
            >Cargo: {{ credentialData.typeName || "Ninguno" }}</span
          >
        </div>

        <div class="qr-faboxeo">
          <img [src]="qrImage" />
          <span class="text-center"> N° {{ userData.identityNumber }}</span>
        </div>
      </section>
      <section class="datos description">
        <img class="align-items-xxl-end user-photo" [src]="userData.photo" />
        <div class="datos description-container">
          <div class="datos">
            <mat-icon>person</mat-icon
            ><span> DNI: {{ userData.identityNumber }}</span>
          </div>
          <div class="datos">
            <mat-icon>location_on</mat-icon
            ><span> Provincia: {{ userData.location }}</span>
          </div>
          <div style="margin: 3px"></div>
          <div class="datos">
            <mat-icon>credit_card</mat-icon
            ><span class="valid_until">
              Vencimiento:
              <b>{{ formatExpiration(credentialData.valid_until) }}</b></span
            >
          </div>
        </div>
      </section>
    </div>
    <label
      class="form-label expired-title title"
      [class.disabled-title]="!['valid', 'expired'].includes(credentialStatus)"
      >{{ credentialType.toUpperCase() }}</label
    >
  </div>
</div>
