import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LicenseListTable, LicenseTypeItem } from "src/app/models/license";
import { Person } from "src/app/models/person";
import { ApiLicensesService } from "src/app/services/api-licenses.service";

@Component({
  selector: "licenses-list",
  templateUrl: "./licenses-list.component.html",
  styleUrls: ["./licenses-list.component.scss"],
})
export class LicenseListComponent implements OnInit {
  personsList: Person[] = [];
  licenseTypeList: LicenseTypeItem[] = [];
  licenseList: LicenseListTable = {};
  today: Date = new Date();

  constructor(
    private APILicenses: ApiLicensesService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getLicenseTypes();
  }

  new() {
    console.log("new person for license");
    this.router.navigate(["licenses-form"]);
  }

  goToEdit(id: number) {
    this.router.navigate(["licenses-form", id]);
  }

  getLicenseTypes() {
    this.APILicenses.getTypes().subscribe((response) => {
      this.licenseTypeList = response.data
        .sort((a: { name: string }, b: { name: string }) =>
          a.name.localeCompare(b.name),
        )
        .reverse();
      console.log("Tipos de licencias: ", response.data);
    });
    return this.licenseTypeList;
  }
}
