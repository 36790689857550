import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import { IdentityService } from "../services/identity.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private identity: IdentityService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.identity.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(["/login"]);
          return false;
        }
        const profiles = next.data["roles"] || [];

        if (profiles.length < 1) return true;

        const allProfiles = this.identity.getAllProfiles;

        if (profiles.find((prof: number) => allProfiles.includes(prof)))
          return true;

        this.router.navigate(["/"]);
        return false;
      }),
    );
  }
}
