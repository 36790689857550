import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "file-button",
  templateUrl: "./file-button.component.html",
  styleUrls: ["./file-button.component.scss"],
})
export class FileButtonComponent implements OnInit {
  @Input() color: string = "";
  @Input() text: string = "";
  @Input() icon: string = "";
  @Input() hideFileName: boolean = false;
  @Input() disableButton: boolean = false;

  @Output() fileChanged: EventEmitter<{ base64: any; type: string }> =
    new EventEmitter();

  fileName: string;

  constructor() {}

  ngOnInit(): void {}

  onChangeInputFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileChanged.emit({ base64: reader.result, type: file.type });
      !this.hideFileName && (this.fileName = file.name);
    };
  }
}
