import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserPerson } from "src/app/models/user-person";
import { ApiChangeProfileRequestsService } from "src/app/services/api-change-profile-requests.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

type RequestData = {
  id: string;
  person_id: string;
  status: string;
  identity_number: string;
  person_name: string;
  email: string;
};

@Component({
  selector: "app-change-profile-requests-details",
  templateUrl: "./change-profile-requests-details.component.html",
  styleUrls: ["./change-profile-requests-details.component.scss"],
})
export class ChangeProfileRequestsDetailsComponent implements OnInit {
  changeRequestId: number;
  dataLoaded: boolean = false;
  requestData: RequestData;
  originalInformation: Partial<UserPerson>;
  newInformation: Partial<UserPerson>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ApiChangeProfileRequests: ApiChangeProfileRequestsService,
    private ui: UserInterfaceService,
  ) {
    this.route.params.subscribe((params) => {
      this.changeRequestId = params["changeRequestId"];
    });
  }

  ngOnInit(): void {
    this.ui.spin$.next(false);
    this.getProfileRequests();
  }

  getProfileRequests() {
    this.ApiChangeProfileRequests.getChangeRequest(
      this.changeRequestId,
    ).subscribe((response) => {
      const { original_values, modified_values, ...requestData } =
        response.data;
      this.originalInformation = original_values;
      this.newInformation = modified_values;
      this.requestData = requestData;

      this.dataLoaded = true;
      this.ui.spin$.next(false);
    });
  }

  sendRequest(status: boolean) {
    const { id, person_id, email } = this.requestData;
    const newInformationParsed = this.newInformation;

    newInformationParsed.photo = newInformationParsed.photo?.split("/").pop();
    newInformationParsed.identity_card_front =
      newInformationParsed.identity_card_front?.split("/").pop();
    newInformationParsed.identity_card_back =
      newInformationParsed.identity_card_back?.split("/").pop();

    this.ui.spin$.next(true);
    this.ApiChangeProfileRequests.updateStatusChangeRequest(
      Number(id),
      Number(person_id),
      status,
      email,
      newInformationParsed,
    ).subscribe((response) => {
      this.ui.spin$.next(false);
      alert(response.message);
      this.router.navigate(["change-profile-requests"]);
    });
  }
}
