import { Component, Input, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { CustomTableColumnType } from "src/app/components/custom-table/enums/custom-table-column-type.enum";
import { CustomTableColumn } from "src/app/components/custom-table/interfaces/custom-table-column";
import { CustomTableGenericButton } from "src/app/components/custom-table/interfaces/custom-table-generic-button";
import { CustomTableOptions } from "src/app/components/custom-table/interfaces/custom-table-options";
import { CustomTableStructure } from "src/app/components/custom-table/interfaces/custom-table-structure";
import { LicenseCreateDialogComponent } from "src/app/components/license-create-dialog/license-create-dialog.component";
import { LicenseDialogComponent } from "src/app/components/license-dialog/license-dialog.component";
import { License } from "src/app/models/license";
import { Person } from "src/app/models/person";
import { ApiLicensesService } from "src/app/services/api-licenses.service";
import { ApiPersonsService } from "src/app/services/api-persons.service";
import { UtilsService } from "src/app/services/utils.service";

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: "license-list-table",
  templateUrl: "./license-list-table.component.html",
  styleUrls: ["./license-list-table.component.scss"],
})
export class LicenseListTableComponent implements OnInit {
  today: Date = new Date();
  @Input() licenseType: string = "";
  @Input() label: string = "Licenses";

  allPersonListCols: CustomTableColumn[] = [
    { name: "id_person", text: "Legajo", type: CustomTableColumnType.TEXT },
    { name: "firstname", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "lastname", text: "Apellido", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
  ];

  licenseListCols: CustomTableColumn[] = [
    { name: "id", text: "ID", type: CustomTableColumnType.TEXT },
    { name: "id_person", text: "Legajo", type: CustomTableColumnType.TEXT },
    { name: "firstname", text: "Nombre", type: CustomTableColumnType.TEXT },
    { name: "lastname", text: "Apellido", type: CustomTableColumnType.TEXT },
    { name: "identity_number", text: "DNI", type: CustomTableColumnType.TEXT },
    {
      name: "valid_until",
      text: "Vencimiento",
      type: CustomTableColumnType.DATE,
    },
    { name: "valid", text: "Vigencia", type: CustomTableColumnType.SEMAPHORE },
    {
      name: "enabled",
      text: "Habilitada",
      type: CustomTableColumnType.BOOLEAN_SEMAPHORE,
    },
  ];
  createLicenseButton: CustomTableGenericButton = {
    icon: "sports_mma",
    text: "Crear Licencia",
    color: "primary",
    title: "Crear licencia",
  };

  allPersonListTableOptions: CustomTableOptions = {
    filtering: true,
    sorting: false,
  };

  licenseListTableOptions: CustomTableOptions = {
    filtering: true,
    sorting: false,
  };

  allPersonListTableStructure: CustomTableStructure = {
    columns: this.allPersonListCols,
    data: [],
    options: this.allPersonListTableOptions,
  };

  licenseListTableStructure: CustomTableStructure = {
    columns: this.licenseListCols,
    data: [],
    options: this.licenseListTableOptions,
  };

  selected_id_person: number = 0;
  selected_identity_number: string = "";

  constructor(
    private utils: UtilsService,
    private APILicenses: ApiLicensesService,
    private APIPersons: ApiPersonsService,
    private licenseDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getAllPersonData();
    this.getAllLicensesWithPersonData(this.licenseType);
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  generateColumns(): any[] {
    const licenseKeys = Object.keys(new License() as License);
    console.log("License keys", licenseKeys);
    return licenseKeys.map((key) => ({
      field: key,
      header: this.capitalizeFirstLetter(key),
    }));
  }

  getAllPersonData() {
    this.APILicenses.getAllWithPersonData("no-license").subscribe(
      (response) => {
        console.log(`getAllWithPersonData[${"no-license"}]: `, response.data);

        this.allPersonListTableStructure.data = response.data;
      },
    );
  }

  getAllLicensesWithPersonData(licenseType: string) {
    this.APILicenses.getAllWithPersonData(licenseType).subscribe((response) => {
      console.log(`getAllWithPersonData[${licenseType}]: `, response.data);

      this.licenseListTableStructure.data = response.data.map(
        (license: License) => {
          return {
            ...license,
            valid: this.utils.getValidLicenseStatusSemaphore(license.valid),
            enabled: this.utils.getValidEnabledBooleanSemaphore(
              license.enabled,
            ),
          };
        },
      );
    });
  }

  openCreateLicenseDialog(id_person: number) {
    //get person data
    let person: Person;
    this.APIPersons.getById(id_person).subscribe((response) => {
      person = response.data;
      console.log("Person", person);
      const dialogRef = this.licenseDialog.open(LicenseCreateDialogComponent, {
        width: "90%",
        maxWidth: "800px",
        data: {
          title: `Crear Licencia para: ${person.firstname} ${person.lastname}`,
          person: person,
        },
      });
    });
  }

  openEditLicenseDialog(id_license: number) {
    console.log("openEditLicenseDialogLicenseId", id_license);
    //get person data
    this.APILicenses.getPersonIdByLicenseId(id_license).subscribe(
      (response) => {
        this.selected_id_person = response.data.id_person;
        console.log("selected_id_person", this.selected_id_person);

        this.APILicenses.getAllWithPersonData(
          this.licenseType,
          this.selected_id_person,
        ).subscribe((response2) => {
          console.log("responseAlWIthPersonData", response2);
          this.selected_identity_number = response2.data[0]?.identity_number;

          const dialogRef = this.licenseDialog.open(LicenseDialogComponent, {
            width: "90%",
            maxWidth: "800px",
            data: {
              title: `Editar Licencia N° ${this.selected_identity_number}`,
              license: response2.data,
              personId: this.selected_id_person,
              creatingLicense: false,
            },
          });
        });
      },
    );
  }

  isExpired(date: string): boolean {
    return this.utils.isExpired(date);
  }

  delete(id: number): void {
    console.log("Delete license", id);
  }

  modifyLicense(license: License): void {
    console.log("Modify license", license);
  }
}
