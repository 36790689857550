import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiBaseService } from "./api-base.service";

@Injectable({
  providedIn: "root",
})
export class ApiReportsService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "reports";
  }

  /**
   * @ngdoc function
   * @name exportExcelStudentsCourses
   * @methodOf ApiReportsService
   * @description Se obtiene el listado de alumnos y sus cursos.
   * @return HttpBaseResponse
   */

  exportExcelStudentsCourses() {
    const endpoint = `${this._apiUrl}/${this._controller}/students_courses_excel`;
    return this.http.get(endpoint, { responseType: "blob" });
  }

  exportPaymentsStatus() {
    const endpoint = `${this._apiUrl}/${this._controller}/payments_status_excel`;
    return this.http.get(endpoint, { responseType: "blob" });
  }
}
