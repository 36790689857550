<h2 mat-dialog-title>{{ elements.title }}</h2>
<div mat-dialog-content>{{ elements.content }}</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickCancel()">
    {{ elements.closeButtonLabel }}
  </button>
  <button
    mat-button
    *ngIf="elements.okButtonLabel != null"
    cdkFocusInitial
    (click)="onClickOk()"
    color="primary"
  >
    {{ elements.okButtonLabel }}
  </button>
</div>
