import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-payments-information",
  templateUrl: "./payments-information.component.html",
  styleUrls: ["./payments-information.component.scss"],
})
export class PaymentsInformationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
