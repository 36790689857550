import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PointGroup } from "signature_pad";
import { SignatureDialogComponent } from "src/app/components/signature-dialog/signature-dialog.component";
import { ApiCoursesService } from "src/app/services/api-courses.service";
import { ApiUsersService } from "src/app/services/api-users.service";
import { IdentityService } from "src/app/services/identity.service";
import { UserInterfaceService } from "src/app/services/user-interface.service";

@Component({
  selector: "app-amateur-license-form",
  templateUrl: "./amateur-license-form.component.html",
  styleUrls: ["./amateur-license-form.component.scss"],
})
export class AmateurLicenseFormComponent implements OnInit {
  userId: number = 0;
  personId: number = 0;

  title: string = "Formulario de Licencia Amateur";
  today: Date = new Date();
  enrollment_enabled: boolean = false;

  licenseForm: UntypedFormGroup;
  studiesOptions: any[] = [
    { id: 1, value: "Primario" },
    { id: 2, value: "Secundario" },
    { id: 3, value: "Terciario" },
    { id: 4, value: "Universitario" },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private matDialog: MatDialog,
    private APIUsers: ApiUsersService,
    private identity: IdentityService,
    private APICourses: ApiCoursesService,
    private router: Router,
    private route: ActivatedRoute,
    private ui: UserInterfaceService,
  ) {
    this.licenseForm = this.fb.group({
      id_person: { value: null, disabled: true },
      email: { value: null, disabled: true },
      firstname: { value: null, disabled: true },
      lastname: { value: null, disabled: true },
      birthdate: { value: null, disabled: true },
      identity_number: { value: null, disabled: true },
      address: { value: null, disabled: true },
      postal_code: { value: null, disabled: true },
      id_nationality: { value: null, disabled: true },
      id_state: { value: null, disabled: true },
      id_city: { value: null, disabled: true },
      gender: { value: null, disabled: true },
      phone: { value: null, disabled: true },
      photo: { value: null, disabled: true },
      studies: [null, Validators.required],
      declared_understand: [false, Validators.required],
    });

    this.userId = this.identity.getDecodedToken().id_user;
    this.personId = this.identity.getDecodedToken().id_person;
    this.ui.spin$.next(true);
    // this.route.params.subscribe((params) => {
    //   if (params["courseId"]) {
    //     this.licenseForm.get("id")?.setValue(params["courseId"]);
    //     this.getCourse();
    //   }
    // });
  }

  ngOnInit(): void {
    this.APIUsers.getUserPerson(this.userId, this.personId).subscribe(
      (response) => {
        response.data.active = response.data.active === "1";
        console.log("response", response.data);
        this.licenseForm.patchValue(response.data);

        this.ui.spin$.next(false);
      },
    );
  }

  // getCourse() {
  //   this.ui.spin$.next(true);
  //   this.APICourses.getAll(this.licenseForm.get("id")!.value).subscribe({
  //     next: (response) => {
  //       this.ui.spin$.next(false);
  //       this.enrollment_enabled = response.data.enrollment_enabled == 1;
  //       response.data.enrollment_enabled = response.data.enrollment_enabled == 1;
  //       this.licenseForm.setValue(response.data);
  //     },
  //   });
  // }

  finish() {
    const dialogRef = this.matDialog.open(SignatureDialogComponent, {
      // width: "90%",
      // maxWidth: "800px",
    });

    dialogRef.afterClosed().subscribe((dataSign) => {
      if (!dataSign) return;
      this.saveCourse(dataSign);
    });
  }

  saveCourse(dataSign: PointGroup[]) {
    console.log(this.licenseForm.getRawValue());
    console.log("dataSign", dataSign);
    console.log("dataSign", typeof dataSign);
    // if (!this.licenseForm.valid) return;

    // let course: Course = new Course();
    // course = this.licenseForm.getRawValue();

    // this.APICourses.set(course).subscribe((response) => {
    //   if (response.id_course) this.router.navigate(["courses-list"]);
    // });
  }
}
