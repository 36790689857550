import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Credential } from "src/app/models/credential";

import { HttpBaseResponse } from "../models/base-response";
import { ApiBaseService } from "./api-base.service";

@Injectable({
  providedIn: "root",
})
export class ApiCredentialsService extends ApiBaseService {
  constructor(private http: HttpClient) {
    super();
    this._controller = "credentials";
  }

  /**
   * @ngdoc function
   * @name getAll
   * @param personId number
   * @methodOf ApiCredentialsService
   * @description Se obtiene la información de las credenciales.
   * @return HttpBaseResponse
   */

  getAll(personId?: number) {
    let endpoint = `${this._apiUrl}/${this._controller}/all`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getAllWithPersonData(credentialType: string, personId?: number) {
    if (credentialType === "all") {
      return this.http.get<HttpBaseResponse>(
        `${this._apiUrl}/${this._controller}/with_person_data/all`,
      );
    }
    let endpoint = `${this._apiUrl}/${this._controller}/with_person_data/${credentialType}`;
    if (personId) endpoint += `/${personId}`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  getPersonIdByCredentialId(credentialId: number) {
    return this.http.get<HttpBaseResponse>(
      `${this._apiUrl}/${this._controller}/person_id/${credentialId}`,
    );
  }

  getTypes() {
    const endpoint = `${this._apiUrl}/${this._controller}/types`;

    return this.http.get<HttpBaseResponse>(endpoint);
  }

  save(credentialData: Credential) {
    return this.http.post<any>(
      `${this._apiUrl}/${this._controller}/form`,
      credentialData,
    );
  }
}
