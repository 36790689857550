import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "record-form",
  templateUrl: "./record-form.component.html",
  styleUrls: ["./record-form.component.scss"],
})
export class RecordFormComponent implements OnInit {
  title: string = "Datos del Record";

  personId: number = 0;

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      if (params["personId"]) {
        this.personId = params["personId"];
      }
    });
  }

  ngOnInit(): void {}
}
